import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/logo-white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

export default function Footer() {
  return (
    <footer className="relative bg-gray-900 overflow-hidden">
      <div className="py-[30px] px-0 border-t border-slate-800">
        <div className="container relative text-center">
          <div className="w-full flex justify-between flex-wrap gap-10 px-2 items-center">
            {/* Logo & Privacy Policy */}
            <div className="md:text-start text-center flex flex-col gap-5">
              <Link to="#" className="text-[22px] focus:outline-none">
                <img
                  src={Logo}
                  width={200}
                  height={40}
                  className="mx-auto md:me-auto md:ms-0 py-5"
                  alt="Logo"
                />
              </Link>
              <div className="text-center space-y-6">
                <p className="text-bold text-white text-3xl text-start flex gap-2">
                  <FontAwesomeIcon icon={faPhone} className="text-white" />
                  Contact Us:
                </p>
                <div className="flex gap-4 flex-wrap">
                  <a
                    href="https://wa.me/201223754659"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    01223754659
                  </a>

                  <a
                    href="https://wa.me/201018944139"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    01018944139
                  </a>
                  <a
                    href="https://wa.me/201140328283"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    01140328283
                  </a>
                </div>
                <div className="text-bold  text-3xl text-start flex gap-2 mt-5 ">
                  <span className=" text-white px-2 py-1 rounded-md mx-3">
                    Email:
                  </span>
                  <a
                    href="mailto:nineacademy1981@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" text-white hover:text-red-500 hover:border-b "
                  >
                    nineacademy1981@gmail.com
                  </a>
                </div>
              </div>
            </div>

            {/* Contact Information */}

            {/* Address & Location Map */}
            <div className="text-white text-start space-y-3">
              <p className="text-3xl font-bold flex items-center gap-2">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                Address:
              </p>
              <p className="text-3xl">
                Nine “9” Center
                <br />
                ٧ ش الشهيد مختار سعيد متفرع من اسماء فهمي، أرض الجولف. بجانب
                الرقابة الإدارية
                <br />
              </p>
              <iframe
                title="Nine 9 Center Location"
                src="https://maps.google.com/?q=30.079325,31.339050&output=embed"
                width="100%"
                height="200"
                className="rounded-lg border border-gray-500"
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="py-7 border-t border-t-gray-400 w-full flex sm:flex-col gap-3 justify-between items-center px-5">
        <div className="container">
          <p className="text-white text-3xl">
            © Copyright 2024{" "}
            <a href="https://natlix.com" className="hover:text-blue-700">
              Natlix
            </a>
            .
          </p>
          <div className="flex gap-3 items-center">
            <Link
              to={`/privacy-policy`}
              className="text-white text-xl font-bold block mt-2"
            >
              Privacy Policy
            </Link>
            <Link
              to={`/terms-conditions`}
              className="text-white text-xl font-bold block mt-2"
            >
              Terms & Conditions
            </Link>
            <Link
              to={`/refund-cancellation-policy`}
              className="text-white text-xl font-bold block mt-2"
            >
              Refund & Cancellation Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
