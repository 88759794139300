import {
  faChevronUp,
  faDownload,
  faFileLines,
  faFilePdf,
  faFilePen,
  faFileSignature,
  faInfinity,
  faLock,
  faLockOpen,
  faMobile,
  faSpinner,
  faTv,
  faUnlockKeyhole,
  faUserGraduate,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useReducer, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader.jsx";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { Popconfirm, Modal } from "antd";
import SingleLectureCountDown from "../Lectures/components/SingleLectureCountDown.jsx";
import VdoCiperrPlayer from "../Lectures/vdoCipherComponents/VdoCiperrPlayer.jsx";
import Visa from "../../../assets/visa.png";
import Master from "../../../assets/card.png";
import { BunnyVideo } from "../Lectures/components/BunnyVideo.jsx";

function PublicLecture() {
  const { lectureID } = useParams();
  const [videoRef, setVideoRef] = useState(null);
  const videoContainerRef = useRef();
  const [isModalVisible, setIsModalVisible] = useState(false);
  /*  const { loadVideo, isAPIReady } = useVdocipher(); */
  const navigate = useNavigate();
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Function to handle modal close
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  function reducer(state, action) {
    switch (action.type) {
      case "setCollapse": {
        return {
          ...state,
          collapse: action.payload,
        };
      }
      case "setSrc": {
        return {
          ...state,
          src: action.payload,
        };
      }
      case "setCurrentVideo": {
        return {
          ...state,
          currentVideo: action.payload,
        };
      }
      case "setPlatform": {
        return {
          ...state,
          platform: action.payload,
        };
      }
      case "setBuyLecture": {
        return {
          ...state,
          buyLecture: action.payload,
        };
      }
      case "setBuyLectureByPoints": {
        return {
          ...state,
          buyLectureByPoints: action.payload,
        };
      }
      case "setBuyLectureByAmount": {
        return {
          ...state,
          buyLectureByAmount: action.payload,
        };
      }
      case "setRefetch": {
        return {
          ...state,
          refetch: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    collapse: false,
    src: "",
    refetch: false,
    currentVideo: null,
    buyLecture: {
      flag: "",
      dependency: false,
      data: {},
    },
    buyLectureByPoints: {
      flag: "",
      dependency: false,
      data: {},
    },
    buyLectureByAmount: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  //!---- get lecture info -------
  const [LectureInfoSuccess, LectureInfoErrors, LectureInfoLoading] = useAxios(
    `${process.env.REACT_APP_LECTURE_VIEW_GET_API}${lectureID}`,
    "GET",
    lectureID,
    state.refetch
  );

  //!---- buy lecture  -------
  const [buyLectureSuccess, buyLectureErrors, submitLoading] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_LECTURE_API,
    "POST",
    state.buyLecture.flag,
    state.buyLecture.dependency,
    state.buyLecture.data,
    true
  );
  //!---- buy lecture By Points -------
  const [
    buyLectureByPointsSuccess,
    buyLectureByPointsErrors,
    buyLectureByPointsLoading,
  ] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_BY_POINTS_API,
    "POST",
    state.buyLectureByPoints.flag,
    state.buyLectureByPoints.dependency,
    state.buyLectureByPoints.data,
    true
  );
  // !---- buy lecture By amount -------
  const [
    buyLectureByAmountSuccess,
    buyLectureByAmountErrors,
    buyLectureByAmountLoading,
  ] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_BY_AMOUNT_API,
    "POST",
    state.buyLectureByAmount.flag,
    state.buyLectureByAmount.dependency,
    state.buyLectureByAmount.data,
    true
  );

  //!---refetch the lecture data again if the code is correct to open the paid content

  useEffect(() => {
    if (
      buyLectureSuccess ||
      buyLectureByPointsSuccess ||
      buyLectureByAmountSuccess
    ) {
      dispatch({
        type: "setRefetch",
        payload: !state.refetch,
      });
      reset();
    }
  }, [buyLectureByPointsSuccess, buyLectureSuccess, buyLectureByAmountSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
  });

  const handleBuyLecture = (data) => {
    navigate("/signin");
  };
  const handleBuyLectureByPoints = () => {
    dispatch({
      type: "setBuyLectureByPoints",
      payload: {
        flag: "buyLectureByPoints",
        dependency: !state.buyLectureByPoints.dependency,
        data: { id: lectureID },
      },
    });
  };
  const handleBuyLectureByAmount = () => {
    dispatch({
      type: "setBuyLectureByAmount",
      payload: {
        flag: "buyLectureByAmount",
        dependency: !state.buyLectureByAmount.dependency,
        data: { id: lectureID },
      },
    });
  };
  if (LectureInfoLoading) {
    return <Loader />;
  }
  if (LectureInfoErrors && typeof LectureInfoErrors === "string") {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{LectureInfoErrors}</p>
      </div>
    );
  }

  return (
    <section
      className={`width min-h-screen   py-20 dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Lectures | Mr Ibrahim Abdel Masseh"></HelmetTags>
      {/**
       * //!---- lecture-content and buying form -----
       */}
      <div className="lecture-top flex w-full justify-between gap-20 flex-col items-center ">
        {/**
         * //!------content-----
         */}
        <div
          dir="ltr"
          className={` flex flex-col w-full items-center text-center transition-{width} duration-500  ease-in-out md:w-full`}
        >
          <div
            className={`lecture-session-img-container  relative  w-full overflow-hidden rounded-2xl shadow-3xl y p-7  lg:w-full  h-[320px] max-h-[320px]  `}
          >
            <img
              className="h-full w-full cursor-pointer rounded-[20px] object-cover duration-300 hover:saturate-150"
              src={LectureInfoSuccess?.data?.img}
              alt={LectureInfoSuccess?.data?.title}
            />
          </div>
          <div
            className={`title-description mt-8 rounded-2xl w-full shadow-3xl p-5 text-center`}
          >
            <h1 className="lecture-session-title mb-9 text-4xl  font-bold">
              {LectureInfoSuccess?.data?.title}
            </h1>
            <h2 className="lecture-session-description text-3xl">
              {LectureInfoSuccess?.data?.description}
            </h2>
            {LectureInfoSuccess?.data?.is_buy === 1 && (
              <SingleLectureCountDown
                expireDate={LectureInfoSuccess?.data?.session_history.expire_at}
                dispatch={dispatch}
                state={state}
              />
            )}
          </div>
        </div>
        {/**
         * //!------buying form-----
         */}
        {LectureInfoSuccess?.data?.is_buy === 0 && (
          <div dir="ltr" className={`  flex  w-full justify-center`}>
            <div className="lecture-session-details-wrapper   flex h-auto w-full items-start overflow-hidden rounded-2xl shadow-3xl p-8   justify-between gap-14 md:flex-col-reverse md:items-center">
              <div className="lock-info relative w-1/2 md:w-full  rounded-xl p-5 flex justify-center">
                <div className="w-[300px] relative py-3 ">
                  <FontAwesomeIcon
                    className=" h-full w-full text-9xl opacity-10 absolute right-0 "
                    icon={faLock}
                  />
                  <h2 className="mb-10 mt-3 text-start text-3xl font-bold  ">
                    This lecture contains:
                  </h2>
                  <ul className="flex flex-col items-start justify-start  gap-8">
                    <li className="flex items-center justify-start gap-4 ">
                      <FontAwesomeIcon icon={faTv} />
                      <p className=" ">Videos.</p>
                    </li>
                    <li className="flex items-center justify-start gap-4">
                      <FontAwesomeIcon icon={faFileLines} /> <p>Exams.</p>
                    </li>

                    <li className="flex items-center justify-start gap-4">
                      <FontAwesomeIcon icon={faMobile} />{" "}
                      <p>Available via mobile and computer.</p>
                    </li>
                  </ul>
                </div>
              </div>

              {LectureInfoSuccess?.data?.price !== 0 ? (
                <div className="form w-1/2 md:w-full  inset-0 flex h-full  items-center justify-center gap-6 p-5">
                  <form
                    method="post"
                    onSubmit={handleSubmit(handleBuyLecture)}
                    className="flex  w-[300px] flex-col items-center gap-8 "
                  >
                    <h3 className="price  my-5 text-center text-3xl font-bold">
                      <span> {LectureInfoSuccess?.data?.price}</span>{" "}
                      <span>EGP</span>
                    </h3>
                    <input
                      className="signin-inputs h-16 w-full rounded-lg pl-7 blur-sm"
                      placeholder="Add your code"
                      disabled
                      type="text"
                      name="code"
                    />
                    {errors.code && (
                      <p className="w-full pb-4 text-center text-[12px] text-blue-900 ">
                        {errors.code.type === "required" &&
                          "Please fill out this field"}
                        {errors.code.type === "maxLength" &&
                          "The maximum number of characters is 255 characters"}
                        {errors.code.type === "minLength" &&
                          "Please write at least one letter"}
                        {errors.code.type === "pattern" &&
                          "Only English letters and numbers are allowed"}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      buyLectureErrors &&
                        buyLectureErrors?.response?.data?.errors?.code && (
                          <p className="w-full  text-[12px] text-blue-900  ">
                            {buyLectureErrors?.response?.data?.errors?.code[0]}
                          </p>
                        )
                    }

                    {/** submit */}

                    <button className="submit mt-6 w-full " type="submit">
                      {submitLoading && state.buyLecture.flag ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Buy the lecture"
                      )}
                    </button>

                    <p className="text-secondary text-2xl font-bold">OR</p>

                    <button
                      type="button"
                      onClick={showModal}
                      className="submit mt-6 w-full flex gap-5 justify-center items-center "
                    >
                      <img src={Visa} alt="visa" className="w-fit h-fit" />
                      <img src={Master} alt="visa" className="w-fit h-fit" />
                    </button>
                    <Modal
                      title="Pay with Visa"
                      visible={isModalVisible}
                      onCancel={handleModalClose}
                      footer={null}
                      width="80%"
                      centered
                    >
                      <iframe
                        src={`https://accept.paymob.com/api/acceptance/iframes/${LectureInfoSuccess?.data?.iframe_id}?payment_token=${LectureInfoSuccess?.data?.payment_key}`}
                        width="100%"
                        height="600px"
                        frameBorder="0"
                        allowFullScreen
                        sandbox="allow-forms allow-scripts allow-same-origin"
                      ></iframe>
                    </Modal>
                  </form>
                </div>
              ) : (
                <p className="w-1/2 md:w-full  inset-0 flex h-full items-center justify-center font-bold text-3xl">
                  Free Lecture
                </p>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="lecture-bottom video-playlist  mt-24 flex w-full items-start justify-between gap-24 lg:gap-16 md:flex-col-reverse md:items-center">
        <div className="w-2/3 md:w-full">
          <div
            className={`
              ${
                LectureInfoSuccess?.data?.session_history?.views === 0
                  ? "hidden"
                  : "flex"
              } video  width justify-end`}
          >
            <div
              className={`iframe-wrapper aspect-video overflow-hidden rounded-2xl  ${
                state.currentVideo !== null && "border-secondary border-2 "
              } w-full`}
            >
              <VdoCiperrPlayer
                videoId={state.currentVideo}
                platform={state.platform}
                // views={state.views}
                history_id={LectureInfoSuccess?.data?.session_history?.key}
                videoRef={videoRef}
                videoContainerRef={videoContainerRef}
                /* isAPIReady={isAPIReady} */
                setVideoRef={setVideoRef}
              />
              {state.platform !== "vdocipher" && state.platform !== "bunny" ? (
                <iframe
                  src={state.src}
                  width="100%"
                  height="100%"
                  title="YouTube video player"
                  //style={{ aspectRatio: "16 / 9" }}
                  allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  id="courseVideo"
                ></iframe>
              ) : null}

              {state.platform === "bunny" && <BunnyVideo src={state.src} />}
            </div>
          </div>
          <p className="font-bold text-3xl mt-12 text-center">
            {state.src && (
              <>
                {LectureInfoSuccess?.data?.session_history?.views === 0
                  ? "You have exceeded the viewing limit"
                  : `views: ${LectureInfoSuccess?.data?.session_history?.views}`}
              </>
            )}
          </p>
        </div>
      </div>
    </section>
  );
}

export default PublicLecture;
