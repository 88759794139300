import HelmetTags from "../../../MainComponents/HelmetTags.jsx";
import Navbar from "../../LandingPage/Components/navbar.js";
import Footer from "../../LandingPage/Components/footer.js";

function RefundCancellation() {
  return (
    <>
      <HelmetTags
        title="Refund & Cancellation Policy | Mr. Ibrahim Abdel Masseh"
        description="Refund & Cancellation Policy Page"
      >
        <link rel="canonical" href="https://ibrahimabdelmaseeh.com/refund-cancellation" />
      </HelmetTags>
      <Navbar />
      <main className="refundCancellationPage p-20 min-h-screen w-full overflow-x-hidden mt-8">
        <h1 className="text-center text-5xl my-6 font-bolder">
          Refund & Cancellation Policy for Mr. Ibrahim Abdel Masseh
        </h1>
        <div>
          <h2 className="text-4xl mb-6">Refund & Cancellation Policy</h2>
          <p className="mb-6">Last updated: December 20, 2023</p>
          <p className="mb-6">
            This Refund & Cancellation Policy outlines the terms and conditions under which refunds and cancellations are processed.
          </p>
          <p>
            By using the Service, you agree to the terms outlined in this policy.
          </p>
        </div>
        <div>
          <h2 className="text-4xl mb-6">Cancellation Policy</h2>
          <p className="mb-6">
            You may cancel your subscription or service at any time. However, cancellations must be made at least 24 hours before the next billing cycle to avoid being charged.
          </p>
        </div>
        <div>
          <h2 className="text-4xl mb-6">Refund Policy</h2>
          <p className="mb-6">
            Refunds are processed on a case-by-case basis. If you are unsatisfied with our service, please contact us within 7 days of your purchase to request a refund.
          </p>
        </div>
        <div>
          <h2 className="text-4xl mb-6">Contact Us</h2>
          <p className="mb-6">
            If you have any questions about our Refund & Cancellation Policy, You can contact us:
          </p>
          <ul>
            <li>
              By visiting this page on our website:{" "}
              <a
                href="https://ibrahimabdelmessah.com/"
                rel="external nofollow noopener noreferrer"
                target="_blank"
              >
                https://ibrahimabdelmessah.com/
              </a>
            </li>
          </ul>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default RefundCancellation;