import HelmetTags from "../../../../MainComponents/HelmetTags";
import { DatePicker } from "antd";
import { useEffect, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
const { RangePicker } = DatePicker;
function Accounting() {
  const [date, setDate] = useState([]);
  const [url, setUrl] = useState(""); // URL state to pass to useAxios

  const [getAccountingSucsses, getAccountingErrors] = useAxios(
    url,
    "GET",
    "GET",
    url // Only make the request if the URL is set
  );

  // Handle the date change
  const handleChangeDate = (dates) => {
    setDate(dates);
  };

  // Refetch data only when both start and end dates are set
  useEffect(() => {
    if (date[0] && date[1]) {
      const startDate = date[0];
      const endDate = date[1];
      setUrl(
        `${process.env.REACT_APP_ADMIN_ACCOUNTING_API}/${startDate}/${endDate}`
      );
    }
  }, [date]);

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Accounting | Mr Ibrahim Abdel Masseh"></HelmetTags>
      <div className="mb-20 flex flex-col items-start gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">Accounting</h2>

        <div className=" w-1/2 sm:w-full">
          <RangePicker
            className="signin-inputs"
            onChange={(_, dateString) => handleChangeDate(dateString)}
          />
        </div>
      </div>

      {getAccountingSucsses && (
        <ul className="flex gap-4 flex-col gap-5">
          {/*  <li className="flex-1 border-2 border-secondary rounded-xl p-4">
            Total Codes:{" "}
            <span className="font-bold text-[red]">
              {getAccountingSucsses?.data?.total?.codes} EGP
            </span>{" "}
            {getAccountingSucsses?.data?.codes?.map((code) => (
              <ul className="flex items-center justify-between mt-10 mb-5">
                <li className="w-1/2">
                  <span className="font-bold">Price:</span> {code?.price}
                </li>
                <li className="w-1/2">
                  <span className="font-bold">Total Codes:</span>{" "}
                  {code?.totalCodes}
                </li>
              </ul>
            ))}
          </li> */}
          <li className="flex-1 border-2 border-secondary rounded-xl p-4">
            Total Wallets:{" "}
            <span className="font-bold text-[red]">
              {getAccountingSucsses?.data?.total?.wallets} EGP
            </span>{" "}
            {getAccountingSucsses?.data?.wallets?.map((wallet) => (
              <ul className="flex items-center justify-between mt-10 mb-5">
                <li className="w-1/2">
                  <span className="font-bold">Name:</span> {wallet?.name}
                </li>
                <li className="w-1/2">
                  <span className="font-bold">Group:</span> {wallet?.group}
                </li>
                <li className="w-1/2">
                  <span className="font-bold">Wallet:</span>{" "}
                  {wallet?.totalAmount} EGP
                </li>
              </ul>
            ))}
          </li>
          <li className="flex-1 border-2 border-secondary rounded-xl p-4">
            <p className="font-bold text-[red]">Discounted History</p>
            {getAccountingSucsses?.data?.discounted_history?.map((discount) => (
              <ul className="flex items-center justify-between mt-10 mb-5">
                <li className="w-1/2">
                  <span className="font-bold">Name:</span> {discount?.name}
                </li>
                <li className="w-1/2">
                  <span className="font-bold">Lecture:</span>{" "}
                  {discount?.lecture}
                </li>
                <li className="w-1/2">
                  <span className="font-bold">Discounted:</span>{" "}
                  {discount?.discount}%
                </li>
              </ul>
            ))}
          </li>
          <li className="flex-1 border-2 border-secondary rounded-xl p-4">
            <p className="font-bold text-[red]">Added Students</p>

            {getAccountingSucsses?.data?.added_students?.map((added) => (
              <ul className="flex items-center justify-between mt-10 mb-5">
                <li className="w-1/2">
                  <span className="font-bold">Name:</span> {added?.name}
                </li>
                <li className="w-1/2">
                  <span className="font-bold">Lecture:</span> {added?.lecture}
                </li>
                <li className="w-1/2">
                  <span className="font-bold">Added by:</span> {added?.admin}
                </li>
              </ul>
            ))}
          </li>
        </ul>
      )}
    </section>
  );
}

export default Accounting;
