import HelmetTags from "../../../MainComponents/HelmetTags.jsx";

import Footer from "../../LandingPage/Components/footer.js";
import Navbar from "../../LandingPage/Components/navbar.js";

function TermsCondition() {
  return (
    <>
      <Navbar />
      <HelmetTags
        title="Terms & Conditions | Mr. Ibrahim Abdel Masseh"
        description="Terms & Conditions Page"
      >
        <link rel="canonical" href="https://ibrahimabdelmaseeh.com/terms-conditions" />
      </HelmetTags>

      <main className="termsConditionPage p-20 min-h-screen w-full overflow-x-hidden mt-8">
        <h1 className="text-center text-5xl my-6 font-bolder">
          Terms & Conditions for Mr. Ibrahim Abdel Masseh
        </h1>
        <div>
          <h2 className="text-4xl mb-6">Terms & Conditions</h2>
          <p className="mb-6">Last updated: December 20, 2023</p>
          <p className="mb-6">
            These Terms & Conditions govern your use of our Service and outline
            the rules and guidelines you must agree to in order to use our
            platform.
          </p>
          <p>
            By accessing or using the Service, you agree to be bound by these
            Terms. If you disagree with any part of the terms, you may not
            access the Service.
          </p>
        </div>
        <div>
          <h2 className="text-4xl mb-6">Interpretation and Definitions</h2>
          <h4 className="text-3xl mb-6">Interpretation</h4>
          <p className="mb-6">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h4 className="text-3xl mb-6">Definitions</h4>
          <p className="mb-6">For the purposes of these Terms & Conditions:</p>
          <ul>
            <li>
              <p>
                <strong>Account</strong> means a unique account created for You
                to access our Service or parts of our Service.
              </p>
            </li>
            <li>
              <p>
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                "control" means ownership of 50% or more of the shares, equity
                interest or other securities entitled to vote for election of
                directors or other managing authority.
              </p>
            </li>
            <li>
              <p>
                <strong>Application</strong> refers to the software program
                provided by the Company.
              </p>
            </li>
            <li>
              <p>
                <strong>Company</strong> (referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to Ibrahim Abdel
                Masseh.
              </p>
            </li>
            <li>
              <p>
                <strong>Country</strong> refers to: Egypt
              </p>
            </li>
            <li>
              <p>
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </p>
            </li>
            <li>
              <p>
                <strong>Service</strong> refers to the Application.
              </p>
            </li>
            <li>
              <p>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </p>
            </li>
          </ul>
        </div>
        <div>
          <h2 className="text-4xl mb-6">Acknowledgment</h2>
          <p className="mb-6">
            By using the Service, you acknowledge that you have read,
            understood, and agree to be bound by these Terms & Conditions.
          </p>
        </div>
        <div>
          <h2 className="text-4xl mb-6">User Responsibilities</h2>
          <p className="mb-6">
            You are responsible for maintaining the confidentiality of your
            account and password, and for restricting access to your account.
          </p>
        </div>
        <div>
          <h2 className="text-4xl mb-6">Limitation of Liability</h2>
          <p className="mb-6">
            The Company shall not be liable for any indirect, incidental,
            special, consequential, or punitive damages, including without
            limitation, loss of profits, data, use, goodwill, or other
            intangible losses.
          </p>
        </div>
        <div>
          <h2 className="text-4xl mb-6">Governing Law</h2>
          <p className="mb-6">
            These Terms & Conditions are governed by and construed in accordance
            with the laws of Egypt.
          </p>
        </div>
        <div>
          <h2 className="text-4xl mb-6">Contact Us</h2>
          <p className="mb-6">
            If you have any questions about these Terms & Conditions, You can
            contact us:
          </p>
          <ul>
            <li>
              By visiting this page on our website:{" "}
              <a
                href="https://ibrahimabdelmessah.com/"
                rel="external nofollow noopener noreferrer"
                target="_blank"
              >
                https://ibrahimabdelmessah.com/
              </a>
            </li>
          </ul>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default TermsCondition;
