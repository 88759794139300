import HelmetTags from "../../../MainComponents/HelmetTags.jsx";
import Navbar from "../../LandingPage/Components/navbar.js";
import Footer from "../../LandingPage/Components/footer.js";

function PrivacyPolicyPage() {
  return (
    <div>
      <HelmetTags
        title="Privacy Policy | Mr.Ibrahim Abdel Masseh"
        description="Privacy Policy Page"
      >
        <link
          rel="canonical"
          href="https://ibrahimabdelmaseeh.com/privacy-policy"
        />
      </HelmetTags>
      <Navbar />
      <main className="privacyPolicyPage p-20 min-h-screen w-full overflow-x-hidden mt-8">
        <h1 className="text-center text-5xl my-6 font-bolder">
          Privacy Policy for Mr Ibrahim Abdel Masseh For parents
        </h1>
        <div>
          <h2 className="text-4xl mb-6">Privacy Policy</h2>
          <p className="mb-6">Last updated: December 20, 2023</p>
          <p className="mb-6">
            This Privacy Policy describes Our policies and procedures on the
            collection, use, and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
        </div>
        <div>
          <h2 className="text-4xl mb-6">Interpretation and Definitions</h2>
          <h4 className="text-3xl mb-6">Interpretation</h4>
          <p className="mb-6">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h4 className="text-3xl mb-6">Definitions</h4>
          <p className="mb-6">For the purposes of this Privacy Policy:</p>
          <ul>
            <li>
              <p>
                <strong>Account</strong> means a unique account created for You
                to access our Service or parts of our Service.
              </p>
            </li>
            <li>
              <p>
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                &quot;control&quot; means ownership of 50% or more of the
                shares, equity interest or other securities entitled to vote for
                election of directors or other managing authority.
              </p>
            </li>
            <li>
              <p>
                <strong>Application</strong> refers to the software program
                provided by the Company.
              </p>
            </li>
            <li>
              <p>
                <strong>Company</strong> (referred to as either &quot;the
                Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
                in this Agreement) refers to Ibrahim Abdel Masseh.
              </p>
            </li>
            <li>
              <p>
                <strong>Country</strong> refers to: Egypt
              </p>
            </li>
            <li>
              <p>
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </p>
            </li>
            <li>
              <p>
                <strong>Personal Data</strong> is any information that relates
                to an identified or identifiable individual.
              </p>
            </li>
            <li>
              <p>
                <strong>Service</strong> refers to the Application.
              </p>
            </li>
            <li>
              <p>
                <strong>Service Provider</strong> means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is
                used.
              </p>
            </li>
            <li>
              <p>
                <strong>Usage Data</strong> refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).
              </p>
            </li>
            <li>
              <p>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </p>
            </li>
          </ul>
        </div>
        <div>
          <h2 className="text-4xl mb-6">
            Collecting and Using Your Personal Data
          </h2>
          <h4 className="text-3xl mb-6">Types of Data Collected</h4>
          <h5 className="text-2xl mb-3">Personal Data</h5>
          <p className="mb-3">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul>
            <li>Full name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Billing or shipping address (if applicable)</li>
            <li>Payment information (if applicable)</li>
          </ul>
          <h5 className="text-2xl mb-3">Usage Data</h5>
          <p className="mb-3">
            Usage Data is collected automatically when using the Service.
          </p>
          <p className="mb-3">
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>
          <p className="mb-3">
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>
          <p>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
        </div>
        <div>
          <h2 className="text-3xl mb-3">Use of Your Personal Data</h2>
          <p className="mb-3">
            The Company may use Personal Data for the following purposes:
          </p>
          <ul>
            <li>
              <p>
                <strong>To provide and maintain our Service</strong>, including
                to monitor the usage of our Service.
              </p>
            </li>
            <li>
              <p>
                <strong>To manage Your Account:</strong> to manage Your
                registration as a user of the Service. The Personal Data You
                provide can give You access to different functionalities of the
                Service that are available to You as a registered user.
              </p>
            </li>
            <li>
              <p>
                <strong>For the performance of a contract:</strong> the
                development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any
                other contract with Us through the Service.
              </p>
            </li>
            <li>
              <p>
                <strong>To contact You:</strong> To contact You by email,
                telephone calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application's push notifications
                regarding updates or informative communications related to the
                functionalities, products or contracted services, including the
                security updates, when necessary or reasonable for their
                implementation.
              </p>
            </li>
            <li>
              <p>
                <strong>To provide You</strong> with news, special offers and
                general information about other goods, services and events which
                we offer that are similar to those that you have already
                purchased or enquired about unless You have opted not to receive
                such information.
              </p>
            </li>
            <li>
              <p>
                <strong>To manage Your requests:</strong> To attend and manage
                Your requests to Us.
              </p>
            </li>
            <li>
              <p>
                <strong>For business transfers:</strong> in case of a merger,
                acquisition, or asset sale.
              </p>
            </li>
            <li>
              <p>
                <strong>For other purposes</strong>: such as data analysis and
                improving our Service.
              </p>
            </li>
          </ul>
          <p className="mb-3">
            We may share Your personal information in the following situations:
          </p>
          <ul>
            <li>
              <strong>With Service Providers</strong>
            </li>
            <li>
              <strong>For business transfers</strong>
            </li>
            <li>
              <strong>With Affiliates</strong>
            </li>
            <li>
              <strong>With business partners</strong>
            </li>
            <li>
              <strong>With other users</strong>
            </li>
            <li>
              <strong>With Your consent</strong>
            </li>
          </ul>
        </div>
        <div>
          <h2 className="text-3xl mb-3">Retention of Your Personal Data</h2>
          <p className="mb-3">
            such as data analysis and improving our Service.
          </p>
        </div>
        <div>
          <h2 className="text-3xl mb-3">Transfer of Your Personal Data</h2>
          <p className="mb-3">
            such as data analysis and improving our Service.
          </p>
        </div>
        <div>
          <h2 className="text-3xl mb-3">Delete Your Personal Data</h2>
          <p className="mb-3">
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </p>

          <p className="mb-3">
            You can submit a deletion request by contacting us at 01223754659
            01140328283 01117832041 01018944139 .
          </p>
          <p className="mb-3">
            We will process Your request in accordance with applicable laws and
            regulations.
          </p>
        </div>
        <div>
          <h2 className="text-3xl mb-3">Disclosure of Your Personal Data</h2>

          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              Protect the personal safety of Users of the Service or the public
            </li>
            <li>Protect against legal liability</li>
          </ul>
          <h4 className="text-2xl mb-3">Security of Your Personal Data</h4>
          <p>
            We use commercially acceptable means, including encryption and
            security protocols, to protect Your Personal Data. However, no
            method of transmission over the Internet or method of electronic
            storage is 100% secure, and We cannot guarantee absolute security.
          </p>
        </div>
        <div>
          <h2 className="text-3xl mb-3">Children's Privacy</h2>
          <p className="mb-3">
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under 13. If We become aware that We have collected Personal Data
            from a child under 13, We will take steps to remove that information
            from Our servers immediately.
          </p>
        </div>
        <div>
          <h2 className="text-3xl mb-3">Links to Other Websites</h2>
          <p className="mb-3">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
        </div>
        <div>
          <h2 className="text-3xl mb-3">Changes to this Privacy Policy</h2>
          <p className="mb-3">
            We may update Our Privacy Policy from time to time and will notify
            You by posting the new policy on this page. Any changes are
            effective immediately upon posting.
          </p>
          <p className="mb-3">
            If You have any questions about this Privacy Policy, You can contact
            Us at 01223754659
          </p>
        </div>
        <div>
          <h2 className="text-3xl mb-3">Contact Us</h2>
          <p className="mb-3">
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <ul>
            <li>
              By visiting this page on our website:{" "}
              <a
                href="https://ibrahimabdelmessah.com/"
                rel="external nofollow noopener noreferrer"
                target="_blank"
              >
                https://ibrahimabdelmessah.com/
              </a>
            </li>
          </ul>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default PrivacyPolicyPage;
