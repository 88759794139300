import HelmetTags from "../../../../MainComponents/HelmetTags";
import { DatePicker } from "antd";
import { useEffect, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { CSVLink } from "react-csv";
const { RangePicker } = DatePicker;

function Transaction() {
  const [date, setDate] = useState([]);
  const [url, setUrl] = useState("");
  const [submitTransactions, setSubmitTransactions] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [getTransactionsSuccess, getTransactionsErrors] = useAxios(
    process.env.REACT_APP_ADMIN_TRANSACTIONS_TAB_API,
    "POST",
    submitTransactions.flag,
    submitTransactions.dependency,
    submitTransactions.data,
    true
  );

  // Handle the date change
  const handleChangeDate = (dates) => {
    setDate(dates);
  };

  useEffect(() => {
    if (getTransactionsSuccess) {
      setSubmitTransactions({ flag: "", dependency: false, data: {} });
    }
  }, [getTransactionsSuccess]);

  // Refetch data only when both start and end dates are set
  useEffect(() => {
    if (date[0] && date[1]) {
      const startDate = date[0];
      const endDate = date[1];

      setSubmitTransactions({
        flag: "transactions",
        dependency: !submitTransactions.dependency,
        data: { from: startDate, to: endDate },
      });
    }
  }, [date]);

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Transactions | Mr Ibrahim Abdel Masseh"></HelmetTags>
      <div className="mb-20 flex flex-col items-start gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">
          Transactions
        </h2>

        <div className=" w-1/2 sm:w-full">
          <RangePicker
            showTime
            className="signin-inputs"
            onChange={(_, dateString) => handleChangeDate(dateString)}
          />
        </div>
      </div>
      {getTransactionsSuccess?.data && (
        <button className="w-fit rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
          <CSVLink
            data={getTransactionsSuccess?.data}
            filename={`Transaction Excel.csv`}
          >
            Export
          </CSVLink>
        </button>
      )}

      {getTransactionsSuccess?.data && (
        <ul className="flex gap-4">
          <li className="flex-1 border-2 border-secondary rounded-xl p-4">
            Total Amount:{" "}
            <span className="font-bold text-[red]">
              {getTransactionsSuccess?.data?.reduce((a, b) => a + b.amount, 0)}
              EGP
            </span>{" "}
            {getTransactionsSuccess?.data?.map((wallet) => (
              <ul className="flex items-center justify-between mt-10 mb-5">
                <li className="w-1/2">
                  <span className="font-bold">Name:</span>{" "}
                  {wallet?.student_name}
                </li>

                <li className="w-1/2">
                  <span className="font-bold">Type:</span> {wallet?.type}{" "}
                </li>
                <li className="w-1/2">
                  <span className="font-bold">Amount:</span> {wallet?.amount}{" "}
                  EGP
                </li>
              </ul>
            ))}
          </li>
        </ul>
      )}
    </section>
  );
}

export default Transaction;
